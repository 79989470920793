<template>
<div>
  <h2 class="text-3xl my-3 font-extralight">Seleziona una data</h2>
  <date-picker @input="selDate" format="DD/MM/YYYY" v-model="time1" valueType="format"></date-picker>
</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';
//MM/DD/YYYY
export default {
  name: 'calendar',
  components: { DatePicker },
  data() {
    return {
      time1: null,
    };
  },
  methods:{
    selDate(event){
      this.$emit("dateChanged",event)
    }
  }
}
</script>
