<template>
<div>
<div v-if="Object.keys(availability).length == 0 && selectedDate.length > 0" class="flex flex-col justify-center items-center">
  <div class="md:w-1/2 sm:w-screen">
    <div class="alert alert-error shadow-lg mb-5">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <div>Nessuna disponibilità per la data richiesta!</div>
      </div>
    </div>
    <button class="btn btn-accent" @click="backToDate()">Indietro</button>
  </div>
</div>
<h2 class="text-3xl my-3 font-extralight" v-if="!force_next && Object.keys(availability).length > 0">Seleziona un pacchetto base</h2>
<h2 class="text-3xl my-3 font-extralight" v-if="force_next && Object.keys(availability).length > 0">Opzioni</h2>
<div v-if="Object.keys(availability).length > 0" class="btn-group justify-center items-center">
  <button class="btn" :class="{ 'btn-accent': (filter === '1O2L') }" @click="changeFilter('1O2L')">2 Persone</button>
  <button class="btn" :class="{ 'btn-accent': (filter === '1O3L') }" @click="changeFilter('1O3L')">3 Persone</button>
  <button class="btn" :class="{ 'btn-accent': (filter === 'ISL') }" @click="changeFilter('ISL')">4/5/6 Persone</button>
</div>
<div class="flex flex-col justify-center items-center" v-for="(item, key) in filterbyType" :key="key">
  <div class="md:w-1/2 w-screen">
    <div class="flex flex-row border p-3 rounded-box border-base-300 m-5">
      <div class="w-3/5 lg:w-4/5 lg:align-middle lg:text-xl lg:font-medium">{{item.name}}</div>
      <div class="w-2/5 lg:w-1/5 text-2xl align-middle items-center mt-auto mb-auto">
        <unicon @click="removeElement(key)" name="minus-circle" class="cursor-pointer"/>
        <span class="countdown px-2"><span :style="'--value: '+ item.selection + ';'"></span>
        </span><unicon @click="addElement(key)" name="plus-circle" class="cursor-pointer"/>
      </div>
    </div>
  </div>
</div>
<button v-if="show_next || force_next" class="btn btn-accent btn-active" role="button" aria-pressed="true" @click="sendCart()">Avanti</button>
</div>
</template>
<script>

export default {
  name: 'selectorBase',
  components: {  },
  data() {
    return {
      cart:{},
      show_next: false,
      description: {},
      what_include: {},
      f_cart:{},
      filter:"1O2L"
    };
  },
  props:["availability","force_next","selectedDate"],
  methods:{
    addElement(key){
      console.log(this.availability);
      if(this.availability[key]["available"] == 0)
        return
      if(!(key in this.cart)){
        this.cart[key] = 1
        this.description[key] = this.availability[key]["name"]
        this.what_include[key] = this.availability[key]["explain"]
        this.availability[key]["selection"] = 1
        this.show_next = true
      }
      else {
          if(this.cart[key] < this.availability[key]["available"]){
            this.cart[key] +=1
            this.description[key] = this.availability[key]["name"]
            this.what_include[key] = this.availability[key]["explain"]
            this.availability[key]["selection"] += 1
            this.show_next = true
          }
      }
      if(this.force_next){
        this.show_next = true
      }
    },
    removeElement(key){
      if(! (key in this.cart)){
        this.cart[key] = 0
        this.availability[key]["selection"] = 0
      }
      else {
        if(this.cart[key] > 0){
          this.cart[key] -=1
          this.availability[key]["selection"] -= 1
          this.show_next = false
          if(this.cart[key] == 0){
            delete this.description[key]
            delete this.what_include[key]
            delete this.cart[key]
          }
        }
      }
      if(this.force_next){
        this.show_next = true
      }
    },
    sendCart(){
      this.show_next = false
      this.$emit('Selected',{"cart":this.cart,"description":this.description,"what_include":this.what_include})
    },
    backToDate(){
      this.$emit('backToDate')
    },
    changeFilter(value){
      this.filter = value
    }
  },
  computed:{
    filterbyType(){
      if(this.filter == "*")
        return this.availability
      var t_list = Object.entries(this.availability);
      var filtered = t_list.filter((key) => {
        return key[0].includes(this.filter)
      })
      return Object.fromEntries(filtered)
    }
  }
}
</script>
<style scoped>

</style>
