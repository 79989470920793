<template>
  <div id="app">
    <div v-if="axios_error != ''" class="flex flex-col justify-center items-center">
      <div class="md:w-1/2 sm:w-screen">
        <div class="alert alert-error shadow-lg mb-5">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            <div>{{axios_error}}</div>
            <button class="btn btn-accent" @click="restartBooking()">Indietro</button>
          </div>
        </div>
      </div>
    </div>
    <Calendar v-if="selectedDate.length == 0 && currentRoute == '/'" @dateChanged="showAvailability"/>
    <div v-if="av_received == false && selectedDate.length != 0" class="loader"/>
    <selectorBase v-if="Object.keys(cart_base).length == 0 && av_received" :availability="availability_base" :selectedDate="selectedDate" @Selected="baseSelected" @backToDate="jumpToCalendar"/>
    <selectorBase v-if="Object.keys(cart_base).length > 0 && Object.keys(cart_extra).length == 0 && skipExtra == false" :availability="availability_extra" @Selected="extraSelected" :force_next="true"/>
    <showQuote v-if="Object.keys(cart_extra).length > 0 || (Object.keys(cart_base).length && skipExtra)" :cart_base="cart_base" :desc_base="desc_base" :cart_extra="cart_extra" :desc_extra="desc_extra" :what_include="what_include" :sel_date="selectedDateIT" :totalamount="amount" @gotopayment="prepareForPay"/>
    <button v-if="preparingPay" class="btn btn-accent btn-accent loading" role="button">Redirigo al Pagamento</button>
    <payForm v-if="Object.keys(paymentVars).length > 0" :nexi_vars="paymentVars"/>
    <!-- OTHER ROUTES! -->
    <paySuccess v-if="currentRoute == '/paySuccess'" />
    <payDown v-if="currentRoute == '/payDown'" />
    <validator v-if="currentRoute == '/validator'" :api_endpoint="api_endpoint"/>
    <dashboard v-if="currentRoute == '/dashboard'" />

  </div>
</template>

<script>
import Calendar from './components/calendar.vue'
import selectorBase from './components/selectorBase.vue'
import showQuote from './components/showQuote.vue'
import payForm from './components/payForm.vue'
import paySuccess from './components/paySuccess.vue'
import payDown from './components/payDown.vue'
import validator from './components/validator.vue'
import dashboard from './components/dashboard.vue'

export default {
  name: 'App',
  components: {
    Calendar,
    selectorBase,
    showQuote,
    payForm,
    paySuccess,
    payDown,
    validator,
    dashboard
  },
  data() {
    return {
      currentRoute: window.location.pathname,
      activeStep: "step step-primary",
      offStep:"step",
      selectedDate:"",
      selectedDateIT:"",
      av_received:false,
      api_endpoint:"https://hm0o6rafl4.execute-api.eu-central-1.amazonaws.com/",
      availability_base:{},
      availability_extra:{},
      cart_base:{},
      desc_base:{},
      cart_extra:{},
      desc_extra:{},
      what_include:{},
      step:[true,false,false,false],
      amount:0,
      preparingPay:false,
      reservation:"",
      paymentVars:{},
      axios_error:"",
      skipExtra: false
    }
  },
  methods:{
    showAvailability(event){
      console.log(window.location)
      this.selectedDateIT = event
      var d_split = event.split("/")
      var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[1])-1,parseInt(d_split[0]))
      this.selectedDate = d_temp.toLocaleDateString("en-US",{ month: '2-digit', day: '2-digit',year: 'numeric' })
      this.$http.get(this.api_endpoint+"reservations/availability?selectedDate="+this.selectedDate+"&shortResponse=true")
      .then((response)=>{
        let base = {}
        let extra = {}
        for (let [key, value] of Object.entries(response.data)) {
            if(value["type"] == "extra"){
              if(value["available"] > 0){
                extra[key] = value
              }
            }
            else {
              if(value["available"] > 0){
                base[key] = value
              }
            }
        }
        this.availability_base = base;
        console.log(this.availability_base);
        this.availability_extra = extra;

        this.availability_base = Object.entries(base)
          .sort()
          .reduce((obj, [k, v]) => ({
            ...obj,
            [k]: v
          }), {})
          this.availability_extra = Object.entries(extra)
            .sort()
            .reduce((obj, [k, v]) => ({
              ...obj,
              [k]: v
            }), {})
        this.av_received = true
      })
    },
    jumpToCalendar(){
      this.selectedDate = ""
      this.availability_base = {}
      this.av_received = false
    },
    baseSelected(event){
      this.cart_base = event.cart
      this.desc_base = event.description
      this.what_include = event.what_include
      if(Object.keys(this.availability_extra) == 0 ){
        this.skipExtra = true
        this.extraSelected({"cart":{},"description":{}})
      }
    },
    extraSelected(event){
      this.cart_extra = event.cart
      this.desc_extra = event.description
      console.log(this.cart_base);
      console.log(this.cart_extra);
      this.$http.get(this.api_endpoint+"reservations/estimateCost",{params:{"selectedDate":this.selectedDate,"items":{...this.cart_base,...this.cart_extra}}})
      .then((response)=>{
        this.amount = response.data.amount
      })
    },
    prepareForPay(event){
      this.customer_name = event
      this.preparingPay = true
      var put_data = {selectedDate:this.selectedDate,items:{...this.cart_base,...this.cart_extra},name:this.customer_name,email:""}
      this.$http.put(this.api_endpoint+"reservations",JSON.stringify(put_data))
      .then((response)=>{
        console.log(response);
        if("err" in response.data){
          this.axios_error = "Accidenti!Qualcuno ti ha fregato l'ultimo posto :( !"
          this.preparingPay = false
        }
        else{
          this.reservation = response.data.idReservation
          console.log(this.reservation)
          //GETTING PAYMENT SESSION
          this.$http.get(this.api_endpoint+"reservations/payments",{params:{"reservation":this.reservation}})
          .then((response)=>{
              this.paymentVars = response.data
              this.preparingPay = false

          })
        }
      })
    },
    restartBooking(){
      this.selectedDate = ""
      this.selectedDateIT = ""
      this.cart_base = {}
      this.cart_extra = {}
      this.availability_base = {}
      this.availability_extra = {}
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 3px;
}
</style>
