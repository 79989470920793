<template>
<div id="paysub">
      <form action="https://ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet" method="POST">
          <input type="hidden" name="alias" :value="nexi_vars.alias"/>
          <input type="hidden" name="importo" :value="nexi_vars.importo"/>
          <input type="hidden" name="divisa" :value="nexi_vars.divisa"/>
          <input type="hidden" name="codTrans" :value="nexi_vars.codTrans"/>
          <input type="hidden" name="mail" :value="nexi_vars.mail"/>
          <input type="hidden" name="mac" :value="nexi_vars.mac"/>
          <input type="hidden" name="url" :value="nexi_vars.url"/>
          <input type="hidden" name="url_back" :value="nexi_vars.url_back"/>
          <input type="submit" ref="submitBtn" class="btn btn-accent btn-accent" name="invia" value="Paga">
      </form>
</div>
</template>
<script>

export default {
  name: 'payForm',
  components: {  },
  data() {
    return {
      cart:{},
    };
  },
  mounted: function () {
    this.$refs.submitBtn.click();
  },
  props:["nexi_vars"],
  methods:{}
}
</script>
<style scoped>

</style>
