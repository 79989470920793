<template>
<div>
  <h1 class="text-5xl my-3 font-extralight">Riepilogo</h1>
  <p class="text-lg font-light mb-4">Per il giorno <span class="font-bold">{{sel_date}}</span> hai selezionato:</p>
  <ul class="list-none">
    <li class="text-lg" v-for="(value, name, index) in cart_base" :key="index">{{value}} x <span class="font-bold">{{desc_base[name]}}</span></li>
  </ul>
  <br>
  <p class="text-lg font-light mb-4">Per un costo totale di:</p>
  <div v-if="totalamount == 0" class="loader"/>
  <p class="text-7xl my-3 font-extralight" v-if="totalamount != 0">{{totalamount}}€</p>
  <h1 class="text-xl my-3 font-bold">Il prezzo include:</h1>
  <ul class="list-disc">
    <li class="text-lg" v-for="(value, name, index) in cart_base" :key="index"><span class="italic">{{what_include[name]}}</span></li>
  </ul>
  <div class="my-8">
    <p class="text-lg font-light mb-4">Per completare la prenotazione ci serve il tuo <span class="font-bold">nome e cognome:</span></p>
    <input type="text" placeholder="Nome Cognome" @input="getName" :disabled="validated" class="input input-bordered input-accent w-full max-w-xs text-center">
  </div>
  <div v-if="show_next">
    <p class="font-bold">ATTENZIONE: Affinchè la prenotazione sia valida è necessario completare il pagamento!</p>
    <button   class="btn btn-accent btn-active" role="button" aria-pressed="true" @click="sendCart()">Conferma e Paga</button>
  </div>
</div>
</template>
<script>

export default {
  name: 'selectorQuote',
  components: {  },
  data() {
    return {
      name:{},
      show_next: false,
      validated:false,
      customer_name:""
    };
  },
  props:["totalamount","cart_base","cart_extra","sel_date","desc_base","desc_extra","what_include"],
  methods:{
    getName(event){
      this.customer_name = event.target.value
      if(event.target.value == ""){
        this.show_next = false
      }
      else {
        this.show_next = true
      }
    },
    sendCart(){
      this.show_next = false
      this.validated = true
      this.$emit('gotopayment',this.customer_name)
    }
  }
}
</script>
