<template>
<div>
      <div class="flex flex-col justify-center items-center">
          <div class="text-xl font-bold">Oh no!</div>
          Il pagamento non è andato a buon fine
          
    </div>

</div>
</template>
<script>
export default {
  name: 'payDown',
  data() {
    return {
    };
  },
  components:{
  },
  props:[],
  methods: {
    getQueryVariable: function (variable) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
      return ""
    }
  }
}
</script>
<style scoped>

</style>
