<template>
<div>
      <div class="flex flex-col justify-center items-center">
          <div class="text-xl font-bold">Prentazione Completata!</div>
          Ecco un QRCODE con il numero della tua prenotazione.
          <br>
          <div class="md:w-1/2 sm:w-screen mt-8">
            <qrcode-vue class="flex flex-col justify-center items-center" :value="getQueryVariable('id',true)" :size="size" level="H" />
            {{ getQueryVariable('id',false) }}
          </div>
          <button  class="btn btn-accent btn-active" role="button" aria-pressed="true" @click="generateReport()">Scarica PDF</button>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="lamiaprenotazione"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        ref="html2Pdf"
      >
      <section slot="pdf-content">
        <div class="flex flex-col justify-center items-center">
            <div class="text-xl font-bold">Ecco  la  tua  prenotazione</div>
            Ecco un QRCODE con il numero della tua prenotazione.
            <br>
            <div class="md:w-1/2 sm:w-screen mt-8">
              <qrcode-vue class="flex flex-col justify-center items-center" :value="getQueryVariable('id')" :size="300" level="H" />
              {{getQueryVariable('id') }}
              <br>
              Bagno le Forbici
            </div>
        </div>
      </section>
      </vue-html2pdf>

</div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'paySuccess',
  data() {
    return {
      cart:{},
      size: 200,
    };
  },
  components:{
    QrcodeVue,
    VueHtml2pdf
  },
  props:[],
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    getQueryVariable: function (variable,full_link) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              if(full_link)
                return location.host+"/validator?id="+decodeURIComponent(pair[1]);
              return decodeURIComponent(pair[1]);
          }
      }
      return ""
    }
  }
}
</script>
<style scoped>

</style>
