<template>
<div>
  <button v-if="Object.keys(reservation).length == 0" class="btn btn-accent btn-active" role="button" aria-pressed="true" @click="getReservation()">Verifica</button>
  <div class="inside" v-if="Object.keys(reservation).length > 0">
    <unicon v-if="reservation.status == 'ok'" :height="200" :width="200" name="check" fill="green" />
    <unicon v-if="reservation.status != 'ok'" :height="200" :width="200" name="times-circle" fill="red" />
    <h2 class="text-xl font-bold">{{reservation.name.toUpperCase()}}</h2>
    <h2 class="text-xl font-bold">{{reservation.selectedDate}}</h2>
    <br>
    <h2>Ecco cosa ha prenotato:</h2>
    <ul class="list-none">
      <li class="text-lg" v-for="(value, name, index) in reservation.items" :key="index">{{value}} x <span class="font-bold">{{name}}</span></li>
    </ul>
    <h2 v-if="reservation.markonmap == false" class="text-xl font-bold text-red-500">Attenzione non risulta effettuata assegnazione!</h2>
  </div>
</div>
</template>
<script>

export default {
  name: 'validator',
  data() {
    return {
      reservation:{}
    };
  },
  components:{
  },
  props:["api_endpoint"],
  methods: {
    getQueryVariable: function (variable) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
      return ""
    },
    getReservation: function(){
      this.$http.get(this.api_endpoint+"reservations/info?id="+this.getQueryVariable('id')).then((response)=>{
          console.log(response.data);
          var d_split = response.data.selectedDate.split("/")
          var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[0])-1,parseInt(d_split[1]))
          response.data.selectedDate = d_temp.toLocaleDateString("it-IT",{ month: '2-digit', day: '2-digit',year: 'numeric' })
          this.reservation = response.data
      })
    }
  }
}
</script>
<style scoped>

</style>
