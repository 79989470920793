import Vue from 'vue'
import App from './App.vue'
import './index.css'
import axios from 'axios';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import { uniPlusCircle,uniMinusCircle,uniTrashAlt,uniCheck, uniTimesCircle } from 'vue-unicons/dist/icons'

axios.interceptors.request.use(request => {
  //console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})
Vue.prototype.$http = axios;
Vue.config.productionTip = false
Unicon.add([uniPlusCircle, uniMinusCircle,uniTrashAlt,uniCheck, uniTimesCircle])
Vue.use(Unicon)

new Vue({
  render: h => h(App)
}).$mount('#app')
