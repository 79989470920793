<template>
<div>
  <!-- LOGIN FORM -->
  <div v-if="!validate" class="grid grid-flow-row-dense grid-cols-1 grid-rows-2 content-center">
    <h1 class="text-6xl my-3 font-extralight">Gestione Prenotazioni</h1>
    <div>
      Username<br>
      <input type="text" placeholder="Username" v-model="username" class="input input-bordered input-info w-full max-w-xs text-center">
    </div>
    <div>
      Password<br>
      <input type="password" placeholder="password" v-model="password" class="input input-bordered input-info w-full max-w-xs text-center">
      <br><br>
      <button class="btn btn-accent" @click="validateLogin">Login</button>
    </div>
  </div>
  <!-- END LOGIN FORM -->
  <!-- MAIN PAGE -->
  <div v-if="validate" class="flex flex-row">
    <!-- MOD AVAILABILITY -->
    <div class="basis-1/4 mr-1 ml-1">
      <h2 class="text-3xl my-3 font-extralight">Modifica disponibilità</h2>
      <div v-if="error_newAllocation != ''" class="alert alert-error shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>{{error_newAllocation}}</span>
        </div>
      </div>
      <div v-if="success_newAllocation !=''" class="alert alert-success shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>{{success_newAllocation}}</span>
        </div>
      </div>
      <date-picker @input="getElements" format="DD/MM/YYYY" v-model="sel_date" valueType="format" />
        <div v-if="sel_date != ''">
          <div v-for="(item, key) in elements" :key="key" >
          <label class="label">
            <span class="label-text">{{item.name}} <b>(R:{{elements[key]['available']}})</b></span>
          </label>
          <input type="number" :name="key" @input="onChangeAllocated($event,key)" :value="displayCurrentAllocated(key)">
        </div>
        <button class="btn btn-accent btn-active" role="button" aria-pressed="true" @click="sendNewAllocation(false)">Modifica</button>
        <br>
        <button class="btn btn-secondary btn-active mt-4" role="button" aria-pressed="true" @click="sendNewAllocation(true)">Chiudi Prenotazioni</button>
      </div>
    </div>
    <div class="basis-1/4 mr-1 ml-1">
      <h2 class="text-3xl my-3 font-extralight">Prenotazioni da Mappare</h2>
      <div v-if="error_MarkOnMap != ''" class="alert alert-error shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>{{error_MarkOnMap}}</span>
        </div>
      </div>
      <div v-for="(item, key) in reservToMark" :key="key" class="card w-96 bg-accent text-neutral-content mt-3">
        <div class="card-body items-center text-center">
          <h2 class="card-title">{{item.selectedDate}}</h2>
          <h3>{{item.name}}</h3>
          <ul class="list-none">
            <li class="text-lg" v-for="(value, name, index) in item.items" :key="index">{{value}} x <span class="font-bold">{{itemsCodes[name]["name"]}}</span></li>
          </ul>
          <div class="card-actions justify-end">
            <button class="btn btn-primary" @click="markOnMap(item.idReservation,key)">Smarca</button>
          </div>
        </div>
      </div>
    </div>
    <div class="basis-1/2 mr-1 ml-1">
      <h2 class="text-3xl my-3 font-extralight">Prenotazioni per {{reservationsDateIT}}</h2>
      <date-picker @input="convertReservationDate" format="DD/MM/YYYY" v-model="reservationsDateIT" valueType="format" />
      <div v-if="error_ReservationsDates != ''" class="alert alert-error shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>{{error_ReservationsDates}}</span>
        </div>
      </div>
      <div v-if="reservationForDate.length == 0 && !waitingResponse" class="alert alert-info shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>Nessuna prenotazione per la data selezionata</span>
        </div>
      </div>
      <div v-for="(item, key) in reservationForDate" :key="key" class="card w-96 bg-info text-neutral-content mt-3 ml-2 mr-2">
        <div class="card-body items-center text-center">
          <h1 class="card-title">{{item.name}}</h1>
          <div class="badge badge-secondary">{{item.status}}</div>
          <ul class="list-none">
            <li class="text-lg" v-for="(value, name, index) in item.items" :key="index">{{value}} x <span class="font-bold">{{itemsCodes[name]["name"]}}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- END MAIN PAGE -->
</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
export default {
  name: 'dashboard',
  components: { DatePicker },
  data() {
    return {
      error_newAllocation:"",
      error_MarkOnMap:"",
      error_ReservationsDates:"",
      success_newAllocation:"",
      reservToMark: [],
      itemsCodes : {},
      reservationForDate:[],
      reservationsDate: "",
      reservationsDateIT:"",
      waitingResponse: true,
      username:"",
      password:"",
      elements:{},
      originalElements:{},
      validate:false,
      api_endpoint:"https://hm0o6rafl4.execute-api.eu-central-1.amazonaws.com/",
      sel_date:"",
      allocChanges:{}
    };
  },
  props:[],
  mounted: function () {
    window.setInterval(() => {
      if(this.validate)
        this.getRes2Mark()
    }, 600000)
    window.setInterval(() => {
      if(this.validate)
        this.getReservations()
    }, 600000)
  },
  methods:{
    validateLogin(){
      //FIXME:: YOU NEED TO IMPLEMENT A LAMBDA TO PRECHECK!
      this.validate = true
      //getting all items for codenames conversion
      this.$http.get(this.api_endpoint+"items/listTypes")
      .then((response)=>{
        this.itemsCodes = response.data
      })
      this.getRes2Mark()
      this.reservationsDate = new Date().toLocaleDateString("en-US",{ month: '2-digit', day: '2-digit',year: 'numeric' })
      this.reservationsDateIT = new Date().toLocaleDateString("it-IT",{ month: '2-digit', day: '2-digit',year: 'numeric' })
      console.log(this.reservationsDate);
      this.getReservations()

    },
    getRes2Mark(){
      //getting all reservations to mark
      this.error_MarkOnMap = ""
      this.$http.get(this.api_endpoint+"forbiadmin/getToMark")
      .then((response)=>{
        this.reservToMark = response.data
        this.reservToMark.forEach((item,i) => {
          //setting dates to ita format
          var d_split = item.selectedDate.split("/")
          var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[0])-1,parseInt(d_split[1]))
          this.reservToMark[i].selectedDate=d_temp.toLocaleDateString("it-IT",{ month: '2-digit', day: '2-digit',year: 'numeric' })
        });
      })
    },
    getReservations(){
      this.reservationForDate = []
      var req = {
        "params":{
          "selectedDate":this.reservationsDate,
          "username":this.username,
          "password":this.password
        }
      }
      this.waitingResponse = true
      this.$http.get(this.api_endpoint+"forbiadmin/getReservations",req).then((response)=>{
        this.waitingResponse = false
        if(response.data.length == 0){
          this.reservationForDate = []
        }
        else{
          if("err" in response.data[0]){
            this.error_ReservationsDates = response.data.err
          }
          else{
              this.reservationForDate = response.data
          }
        }
      })
    },
    convertReservationDate(){
      var d_split = this.reservationsDateIT.split("/")
      var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[1])-1,parseInt(d_split[0]))
      this.reservationsDate=d_temp.toLocaleDateString("en-US",{ month: '2-digit', day: '2-digit',year: 'numeric' })
      this.getReservations()
    },
    getElements(){
      this.error_newAllocation=""
      this.success_newAllocation=""
      var d_split = this.sel_date.split("/")
      var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[1])-1,parseInt(d_split[0])).toLocaleDateString("en-US",{ month: '2-digit', day: '2-digit',year: 'numeric' })
      this.sel_dateEN = d_temp
      this.$http.get(this.api_endpoint+"reservations/availability?selectedDate="+d_temp+"&shortResponse=true")
      .then((response)=>{
        this.elements = response.data
        console.log("ecco cosa ho scaricato");
        console.log(this.elements)
        //deep clone for matching differences
        //this.originalElements = JSON.parse(JSON.stringify(this.elements))

      })
    },
    sendNewAllocation(force){
      var changes = {}
      var d_split = this.sel_date.split("/")
      var d_temp = new Date(parseInt(d_split[2]),parseInt(d_split[1])-1,parseInt(d_split[0]))
      var d2send = {
         selectedDate: d_temp.toLocaleDateString("en-US",{ month: '2-digit', day: '2-digit',year: 'numeric' }),
         items: this.allocChanges,
         username: this.username,
         password: this.password,
         force: false
      }
      if(force)
      {
        if(confirm("In questo modo non sarà più possibile prenotare per il giorno "+this.sel_date))
        {
          Object.keys(this.elements).forEach((key) => {
          changes[key] = 0
          });
          d2send.items = changes
          d2send.force = true
        }
        else {
          return
        }

      }
      this.$http.post(this.api_endpoint+"forbiadmin/newAllocation", d2send)
      .then((response)=>{
        if("err" in response.data){
          this.error_newAllocation=response.data.err
        }
        else {
          this.success_newAllocation=response.data.status
        }
      })
    },
    markOnMap(id,idx)
    {
      this.reservToMark.splice(idx, 1)
      var d2send = {
         idReservation: id,
         username: this.username,
         password: this.password
      }
      this.$http.post(this.api_endpoint+"forbiadmin/setToMark", d2send)
      .then((response)=>{
        if("err" in response.data){
          this.error_MarkOnMap=response.data.err
        }
      })
    },
    displayCurrentAllocated(key){
      if(this.sel_dateEN in this.itemsCodes[key]["specialDates"])
        return this.itemsCodes[key]["specialDates"][this.sel_dateEN]
      return this.itemsCodes[key]["allocated"]
    },
    onChangeAllocated(event,key){
      this.allocChanges[key] = parseInt(event.target.value)
      this.itemsCodes[key]["specialDates"][this.sel_dateEN] = parseInt(event.target.value)
    }
  }
}
</script>
